import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBezierCurve, faCode } from '@fortawesome/free-solid-svg-icons'

const Proyects = () => {
  return (
    <div className="md:h-full bg-gray-dark text-white grid grid-cols-1 md:grid-cols-2 font_section_1 lg:-mt-12">
      <div className="px-6 py-2 col-span-1 mt-20">
        <div className="bg-gray-dark-2 px-6 py-12 rounded-lg shadow-xl">
        <div className="flex justify-between">
          <p className="text-green-400 text-2xl">Design</p>
          <FontAwesomeIcon icon={faBezierCurve} className="text-green-400"/>

        </div>
          <p className="text-gray-500 text-sm my-6">Create digital products white unique ideas</p>
        </div>
        <div className="bg-gray-dark-2 px-6 py-12 rounded-lg mt-20 shadow-xl">
        <div className="flex justify-between">
          <p className="text-green-400 text-2xl">Front-End Developer</p>
          <FontAwesomeIcon icon={faCode} className="text-green-400"/>
        </div>
          <p className="text-gray-500 text-sm my-6">I develop front-end with coding super smooth</p>
        </div>
      </div>
      <div className="px-6 py-2 col-span-1 mt-20">
        <div className="w-full">
          <h2 className="text-gray-500 font-light">Introduce</h2>
            <p className="text-5xl my-12">Hello!, I&apos;m Magdiel G.</p>
          <div className="text-xl italic my-20">
            <p>Every great design begins with an even better story</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Proyects
