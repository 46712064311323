const URL_MAIL = 'https://formspree.io/f/xvodjddg'

const sendMail = async (data) => {
  try {
    const res = await fetch(URL_MAIL, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        Accept: 'application/json'
      }
    })
    return res
  } catch (error) {
    const err = new Error(error)
    console.log(err)
  }
}

export default sendMail
