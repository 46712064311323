import React from 'react'
const Header = () => {
  return (
    <>
      <header className="w-full px-12 py-2 pt-8 flex flex-wrap justify-between
      text-white">
        <h1 className="sm:w-1/4 w-10/12 text-xl">
          Magdiel <span className="text-green-400">Dev</span>
        </h1>
      </header>
    </>
  )
}

export default Header
