import React from 'react'
import ContactForm from './components/ContactForm'
import Header from './components/Header/Header'
import Proyects from './components/Proyects/Proyects'
import Section from './components/Section/Section'

const App = () => {
  return (
    <div className="bg-gray-dark">
      <Header />
      <Section />
      <Proyects />
      <ContactForm />
    </div>
  )
}

export default App
