import React, { useState } from 'react'
import sendMail from '../../functions/SendMail'
import Modalconfirmation from '../ModalConfirmation'
// import Modalconfirmation from '../ModalConfirmation'
import WarningError from '../Warning/index'

const Form = () => {
  const [showAnimation, setShowAnimation] = useState(false)
  const [errorMail, setErrorMail] = useState(false)

  const [error, setError] = useState({
    name: false,
    email: false,
    message: false
  })

  const [form, setForm] = useState({
    name: '',
    email: '',
    message: ''
  })

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (form.name.trim() === '' || form.email.trim() === '' || form.message.trim() === '') {
      console.log('Please fill out all fields')
      setError({
        name: true,
        email: true,
        message: true
      })
    } else {
      if (error.name && error.email && error.message) {
        setError({
          name: false,
          email: false,
          message: false
        })
      }
      setForm({
        name: '',
        email: '',
        message: ''
      })
      const msg = await sendMail(form)
      if (msg.status !== 200) {
        setErrorMail(true)
        return setShowAnimation(false)
      }
      setShowAnimation(true)
      setTimeout(() => {
        setShowAnimation(false)
      }, 5000)
    }
  }
  return (
      <>
        <form className="relative" onSubmit={(e) => {
          handleSubmit(e)
        }}>
            <div className="w-full relative flex flex-nowrap items-center">
                <input name="name" value={form.name} type="text" placeholder="Name" className={`w-full px-4 py-1 my-2 rounded border-2 focus:outline-none ${error.name ? 'border-red-500' : 'border-transparent'}`} onChange={(e) => {
                  handleChange(e)
                  setError({
                    ...error,
                    name: false
                  })
                }} />
                {error.name
                  ? <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 absolute right-4 text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                  : null }
            </div>
            <div className="w-full relative flex flex-nowrap items-center">
              <input name="email" value={form.email} type="email" placeholder="Email" className={`w-full px-4 py-1 my-2 rounded border-2 focus:outline-none ${error.email ? 'border-red-500' : 'border-transparent'}`} onChange={(e) => {
                handleChange(e)
                setError({
                  ...error,
                  email: false
                })
              }}/>
              {error.email
                ? <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 absolute right-4 text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                : null }
            </div>
            <div className="w-full relative flex flex-nowrap items-start">
              <textarea name="message" value={form.message} cols="30" rows="10" placeholder="Message" className={`w-full px-4 py-1 my-2 rounded border-2 focus:outline-none ${error.message ? 'border-red-500' : 'border-transparent'}`} onChange={(e) => {
                handleChange(e)
                setError({
                  ...error,
                  message: false
                })
              }}></textarea>
                {error.email
                  ? <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 absolute right-4 text-red-500 top-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                              </svg>
                  : null }
            </div>
            <button type="submit" className="w-full mt-4 bg-blue-500 text-white py-2">Send</button>
            {showAnimation ? <Modalconfirmation /> : null }
            {errorMail ? <WarningError messageError="Oops, an error occurred, try again later, or contact us on WhatsApp."/> : null }
        </form>
      </>
  )
}

export default Form
