import React from 'react'
import jsIcon from '../../assets/image/js-icon.svg'
import reactIcon from '../../assets/image/react-icon.svg'
import swiftIcon from '../../assets/image/swift-icon.svg'
import Lottie from 'react-lottie'
import animateBinary from '../../assets/littie-files/80703-data-in-move.json'

const Section = () => {
  const optionsB = {
    loop: true,
    autoplay: true,
    animationData: animateBinary,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }
  return (
    <>
      <section className="min-h-screen w-11/12 m-auto lg:pt-16 grid grid-cols-1 md:grid-cols-2 place-content-center">
        <div className="col-span-1 flex flex-wrap h-40 md:mt-0 mt-12
        justify-center">
          <div className="mb-10 font_section_1 w-full">
            <button className="px-4 py-1 bg-green-400 rounded">
              Front-End Developer
            </button>
          </div>
          <div className="text-white text-2xl font_section_1 font-light w-full">
            <p>Talk is cheap.</p>
            <p>Show me the code.</p>
          </div>
          <div className="text-gray-500 font_section_1 mt-4 w-full">
            <p>I design and code beatifully simple things.</p>
            <p>and I love what I do.</p>
          </div>
        </div>
        <div className="flex justify-center w-10/12 mt-24 lg:-mt-64 relative m-auto">
          <Lottie options={optionsB} />
          <div className="p-6 bg-gray-dark-2 shadow-xl rounded-full w-20
             h-20 md:w-36 md:h-36 flex items-center justify-center
             absolute right-0">
              <img src={jsIcon} alt="" className="w-9-12 md:w-10/12" />
            </div>
            <div className="p-6 bg-gray-dark-2 shadow-xl rounded-full
             w-20 h-20 md:w-28 md:h-28 flex items-center justify-center absolute
              lg:top-24 -left-2">
              <img src={reactIcon} alt="" className="w-full" />
            </div>
            <div className="p-6 bg-gray-dark-2 shadow-xl rounded-full w-20 h-20
             md:w-24 md:h-24 flex items-center justify-center absolute bottom-0
              lg:bottom-12 left-0">
              <img src={swiftIcon} alt="" className="w-full" />
            </div>
        </div>
      </section>
    </>
  )
}

export default Section
