import React, { useState } from 'react'

const Formwhatsapp = () => {
  const [error, setError] = useState(false)
  const [messageText, setMessageText] = useState({
    message: ''
  })

  const handleChange = (e) => {
    setMessageText({
      ...messageText,
      [e.target.name]: e.target.value
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const messageWhats = messageText.message.replaceAll(' ', '%20')
    const URL_WHATS = `https://web.whatsapp.com/send?phone=526623175386&text=${messageWhats}.`
    if (messageText.message.trim() === '') {
      return setError(true)
    }
    window.open(URL_WHATS, '_blanck').focus()
    setMessageText({
      message: ''
    })
  }

  return (
    <div>
        <form onSubmit={handleSubmit}>
            <div className="w-full relative flex flex-nowrap items-start">
            <textarea name="message"
                value={messageText.message} cols="30" rows="10"
                placeholder="Message"
                className={`w-full px-4 py-1 my-2 rounded border-2 focus:outline-none ${error ? 'border-red-500' : 'border-transparent'}`}
                onChange={(e) => {
                  handleChange(e)
                  setError(false)
                }}>
            </textarea>
            {error
              ? <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 absolute right-4 text-red-500 top-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                              </svg>
              : null }

            </div>
            <button type="submit" className="w-full mt-4 bg-blue-500 text-white py-2">Send</button>
        </form>
    </div>
  )
}

export default Formwhatsapp
