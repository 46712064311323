import propTypes from 'prop-types'
import React from 'react'

const WarningError = ({ messageError }) => {
  return (
    <div className="p-2 border-l-4 border-red-600 w-full bg-red-200 text-red-900 mt-4">
        <p>{messageError}</p>
    </div>
  )
}

export default WarningError

WarningError.propTypes = {
  messageError: propTypes.string.isRequired
}
