import React, { useEffect, useState } from 'react'
import animationLottie from '../assets/littie-files/83667-arty-chat.json'
import animationSucces from '../assets/littie-files/79952-successful.json'
import Lottie from 'react-lottie'

const Modalconfirmation = () => {
  const [showAnimation, setShowAnimation] = useState(true)
  const options = {
    loop: !!showAnimation,
    autoplay: true,
    animationData: showAnimation ? animationLottie : animationSucces,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }
  useEffect(() => {
    setTimeout(() => {
      setShowAnimation(false)
    }, 3000)
    setShowAnimation(true)
  }, [])
  return (
      <div className="w-full h-full absolute bottom-0 left-0">
        <Lottie options={options} />
      </div>
  )
}

export default Modalconfirmation
