import React, { useEffect, useState } from 'react'
import Form from './Form'
import Formwhatsapp from './FormWhatsApp'
import Lottie from 'react-lottie'
import animatePC from '../../assets/littie-files/75061-dashboard.json'

const ContactForm = () => {
  const [showForm, setShowForm] = useState(false)
  const [showFormWhats, setShowFormWhats] = useState(false)
  const [showFrame, setShowFrame] = useState(true)

  const options = {
    loop: true,
    autoplay: true,
    animationData: animatePC,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }

  useEffect(() => {
    if (showForm === false && showFormWhats === false) {
      setShowFrame(true)
    } else {
      setShowFrame(false)
    }
  }, [showForm, setShowFormWhats])

  return (
    <>
    <div className="h-full lg:h-screen mt-14">
      <div className="w-10/12 m-auto">
        <p className="w-full text-5xl font-RobotoMono text-white pb-12">Contact me</p>
      </div>
      <div className="w-10/12 md:w-1/4 m-auto pb-12">
          <div className="w-full flex flex-wrap items-center justify-between pb-12">
            <button type="button" onClick={() => {
              setShowForm(!showForm)
              setShowFormWhats(false)
            }}>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207" />
              </svg>
            </button>
            <button type="button" onClick={() => {
              setShowFormWhats(!showFormWhats)
              setShowForm(false)
              setShowFrame(!showFrame)
            }}>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 64 64"><path fill="#fff" d="M50.801 13.135c-4.739-4.742-11.039-7.354-17.752-7.357-13.837 0-25.094 11.253-25.099 25.085a25.039 25.039 0 0 0 3.349 12.541l-3.56 12.999 13.304-3.488a25.084 25.084 0 0 0 11.996 3.054h.011c13.83 0 25.088-11.256 25.095-25.087.002-6.703-2.607-13.005-7.344-17.747zM33.05 51.733h-.008a20.866 20.866 0 0 1-10.62-2.906l-.762-.452-7.894 2.07 2.108-7.694-.497-.789a20.802 20.802 0 0 1-3.189-11.097c.004-11.496 9.361-20.85 20.87-20.85a20.73 20.73 0 0 1 14.746 6.115 20.733 20.733 0 0 1 6.104 14.752c-.006 11.497-9.363 20.851-20.858 20.851z"></path><path fill="#fff" d="M25.429 19.26a8.65 8.65 0 0 0-1.028.011 2.352 2.352 0 0 0-.95.255c-.221.114-.427.277-.75.582-.305.288-.481.54-.668.782a6.974 6.974 0 0 0-1.443 4.291l.001.003a8.243 8.243 0 0 0 .844 3.607c1.043 2.307 2.763 4.746 5.035 7.008a24.676 24.676 0 0 0 1.657 1.6 24.145 24.145 0 0 0 9.814 5.229s.751.179 1.391.218c.021.001.04.003.061.003a9.207 9.207 0 0 0 1.422-.033 5.086 5.086 0 0 0 2.129-.59c.423-.225.623-.337.978-.561 0 0 .11-.072.319-.23.345-.257.558-.438.845-.736.211-.22.394-.479.534-.772.2-.417.401-1.213.481-1.874.061-.505.042-.781.036-.952-.011-.275-.238-.558-.487-.678l-1.486-.668s-2.222-.967-3.581-1.587a1.278 1.278 0 0 0-.452-.104c-.341-.021-.723.068-.966.324v-.004c-.013-.001-.182.145-2.031 2.385-.102.122-.341.387-.754.362a1.086 1.086 0 0 1-.185-.029 3.402 3.402 0 0 1-.49-.17c-.316-.134-.427-.185-.643-.278l-.013-.006a15.361 15.361 0 0 1-4.013-2.556 15.88 15.88 0 0 1-.927-.885c-1.074-1.041-1.953-2.148-2.607-3.24-.035-.06-.09-.146-.15-.242-.107-.174-.225-.381-.262-.523-.095-.376.157-.678.157-.678s.622-.68.911-1.05c.278-.356.518-.704.671-.952.301-.484.39-.982.238-1.37a216.767 216.767 0 0 0-2.219-5.215c-.156-.339-.598-.589-1.005-.636a6.284 6.284 0 0 0-.414-.041"></path></svg>
            </button>
          </div>
          {showForm ? <Form /> : null}
          {showFormWhats ? <Formwhatsapp /> : null}
          { showFrame
            ? <div className="w-full">
          <Lottie options={options} />
          </div>
            : null }
      </div>
    </div>
    </>
  )
}

export default ContactForm
